@tailwind base;
@tailwind components;
@tailwind utilities;

#root {
  display: flex;
  flex-flow: column;
  min-height: 100%;
}
html,
body {
  height: 100%;
}
body {
  --parchment: #eeeae2;
  background: var(--parchment);
  color: #445;
  margin: 0;
  --dimmed: rgba(255, 255, 255, 0.5);
  --paper: #fcfaf7;
  --lines: #cccac1;
  --crimson: #b7585b;
  font-family: serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.hide-scroll,
.hide-scroll > body {
  scrollbar-width: none;
}
.hide-scroll::-webkit-scrollbar,
.hide-scroll > body::-webkit-scrollbar {
  display: none;
}
.min-scroll,
.min-scroll > body {
  scrollbar-color: #888 transparent;
}
.min-scroll::-webkit-scrollbar,
.min-scroll > body::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background: transparent;
}
.min-scroll::-webkit-scrollbar-thumb,
.min-scroll > body::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a:active {
  opacity: 0.8;
}

.error-icon::before {
  content: "\026a0";
  padding-right: 0.5em;
}

.base-btn:not(:disabled):hover,
.base-btn:not(:disabled):focus-visible {
  opacity: 0.8;
}
.base-btn:not(:disabled):active {
  opacity: 0.6;
}

.placeholder {
  min-height: 1rem;
  min-width: 1rem;
  animation: placeholder 1s linear infinite alternate;
}

@keyframes placeholder {
  from {
    background: rgba(3 3 3/0.1);
  }
  to {
    background: rgba(250 250 250/0.1);
  }
}

.box-shadow {
  box-shadow: 0.2rem 0.2rem 0.8rem rgba(0 0 0/0.5);
}

/* animations */

.slide-in {
  animation: slide-in 1.2s ease-in-out both;
}
@keyframes slide-in {
  from {
    transform: translateY(10vh);
    opacity: 0;
  }
}
.fade-in {
  animation: fade-in 1.2s ease-in-out both;
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
}
.fade-out {
  animation: fade-out 1.2s ease-in-out both;
}
@keyframes fade-out {
  to {
    opacity: 0;
  }
}
.zoom-in {
  animation: zoom-in 1.2s ease-in-out both;
}
@keyframes zoom-in {
  from {
    transform: scale(0.9);
    opacity: 0;
  }
}

*[role="none"],
*[role="none"] .slide-in,
*[role="none"] .fade-out,
*[role="none"] .fade-in,
*[role="none"] .zoom-in {
  animation-name: none;
}
