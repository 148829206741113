.fadein {
  animation: fadein 0.3s both;
}
.fadeout {
  animation: fadeout 0.3s both;
}
.fadein > * {
  animation: grow 0.3s both;
}
.fadeout > * {
  animation: shrink 0.3s both;
}
@keyframes fadein {
  from {
    opacity: 0;
  }
}
@keyframes fadeout {
  to {
    opacity: 0;
  }
}
@keyframes grow {
  from {
    transform: scale(0.5);
  }
  80% {
    transform: scale(1.01);
  }
}
@keyframes shrink {
  to {
    transform: scale(0.5);
  }
}
